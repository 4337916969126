<template>
  <div class="container">
    <cardTitleCom cardTitle="小程序页面路径" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基础页面" name="basics"></el-tab-pane>
            <el-tab-pane label="订单页面" name="order"></el-tab-pane>
            <el-tab-pane label="营销页面" name="marketing"></el-tab-pane>
            <!-- <el-tab-pane label="插件页面" name="plugIn"></el-tab-pane>
            <el-tab-pane label="自定义页面" name="custom"></el-tab-pane> -->
          </el-tabs>
          <el-table :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
            @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" style="width: 100%">
            <el-table-column label="页面名称" prop="name" width="200">
            </el-table-column>
            <el-table-column label="页面链接" prop="path">
            </el-table-column>
            <el-table-column align="right">
              <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
              </template>
              <template slot-scope="scope">
                <div class="x-c cursorP" :class="[!scope.row.hover ? 'disnone' : '']">
                  <!-- <el-button size="mini" type="text" @click="copy(scope.row.path,'1')">复制路径</el-button> -->
                  <el-button size="mini" type="text" @click="copy(scope.row.path,'2')">复制链接</el-button>
                  <el-image :src="require('./qrCode.png')" class="marL10" style="width: 20px;height: 20px;"
                    :preview-src-list="srcList" @click="getShare(scope.row)" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
    <c-dialog title="商城分享码" :width="1000" :showDialog.sync="dialogFormVisible">
      <template #content>
        <div class="y-c" v-loading='dialogLoading'>
          <el-image style="width: 400px; height: 400px" :src="qrCodeUrl" :preview-src-list="[qrCodeUrl]"
            class="marB20" />
          <div class="x-x">
            <div>
              {{ urlName }}
            </div>
            <div class="annotateBlue cursorP" @click="downloadSingleImage(qrCodeUrl)">
              下载
            </div>
          </div>
        </div>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog'
import { mallAnalysisPage, mallShare } from '@/api/O2OMall/config/sysConfig'
import CardTitleCom from '@/views/components/cardTitleCom/index.vue'
import path from '@/views/O2OMall/config/pagePath/path.json'
export default {
  name: 'index',
  components: {
    CDialog,
    CardTitleCom
  },
  data() {
    return {
      dialogLoading: false,
      qrCodeUrl: '',
      urlName: '',
      dialogFormVisible: false,
      hover: true,
      activeName: 'basics',
      tableData: [],
      search: '', // 搜索
      // 基础页面
      // basics: path.basics,
      // order: path.order,
      // marketing: path.marketing,
      // plugIn: path.plugIn,
      // custom: path.custom,
      srcList: [], // 放大的图片
    }
  },
  watch: {
    activeName(nVal) {
      this.tableData = path[nVal]
    }
  },
  created() {
    this.tableData = path[this.activeName]
    this.tableData.forEach(item => {
      item.hover = false
    })
  },
  methods: {
    async getShare(row) {
      try {
        this.dialogFormVisible = true
        this.dialogLoading = true
        this.qrCodeUrl = undefined
        this.urlName = undefined
        const res = await mallAnalysisPage({ pageUrl: row.path })
        this.qrCodeUrl = res.data.codeImgUrl
        this.urlName = row.name
      } catch (error) {
        // this.$message.error('生成商城分享码失败')
      } finally {
        this.dialogLoading = false
      }
    },
    downloadSingleImage(imageUrl) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imageUrl
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          let url = URL.createObjectURL(blob)
          this.download(url, '商城分享码')
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    handleClick(val) {
      console.log('页面tab切换：', val)
    },
    // 复制
    async copy(val, key) {
      // 假设这是你的原始字符串
      let originalString = val
      let spa = ''
      let res = {}
      if (key == 2) {
        // 使用正则表达式替换开头的斜杠
        let newString = originalString.replace(/^\//, '')
        res = await mallShare({ pageUrl: newString })
        spa = res.data
      } else {
        spa = val
      }
      const save = function (e) {
        e.clipboardData.setData('text/plain', spa)
        e.preventDefault() // 阻止默认行为
      }
      document.addEventListener('copy', save) // 添加一个copy事件
      document.execCommand('copy') // 执行copy方法
      this.$message({ message: '复制成功', type: 'success' })
    },
    // 鼠标进入行
    cellMouseEnter(row, column, cell, event) {
      let eleIndex = this.tableData.indexOf(row)
      let Arr = JSON.parse(JSON.stringify(this.tableData))
      for (let i = 0; i < Arr.length; i++) {
        const element = Arr[i]
        if (eleIndex == i) {
          element['hover'] = true
        } else {
          element['hover'] = false
        }
      }
      this.tableData = JSON.parse(JSON.stringify(Arr))

    },
    // 鼠标出行
    cellMouseLeave(row) {
      for (let index = 0; index < this.tableData.length; index++) {
        const element = this.tableData[index]
        element['hover'] = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;
  font-size: 13px;
  .disnone {
    display: none;
  }
}
</style>
